<template>
    <div class="container">
        <!-- 头部 -->
        <Header :tab="4"/>
        <!-- 身体 -->
        <div class="main">
            <div class="banner wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
                <img src="../../assets/imgs/app_banner_0.png" class="banner_img" alt />
                <div class="banner_box">
                    <div class="banner_box_title">定制开发APP专属服务</div>
                    <div class="banner_box_center">满足您对功能的个性化需求</div>
                    <div class="banner_box_txt">我们通过原生或HTML5在IOS/ANDROID上开发的应用,打造流畅的用户体验!</div>
                </div>
                <div class="banner_btn_box">
                    <div class="banner_btn_box_1 wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
                        :data-wow-offset="height_top">
                        <img src="../../assets/imgs/app_banner_icon_1.png" class="banner_btn_box_1_img" alt="">
                    </div>
                    <div class="banner_btn_box wow animate__slideInUp" data-wow-delay="0.4s" data-wow-iteration="1"
                        :data-wow-offset="height_top">
                        <img src="../../assets/imgs/app_banner_icon_0.png" class="banner_btn_box_img" alt="">
                    </div>
                    <div class="banner_btn_box_1 wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
                        :data-wow-offset="height_top">
                        <img src="../../assets/imgs/app_banner_icon_2.png" class="banner_btn_box_1_img" alt="">
                    </div>
                </div>
            </div>

            <!-- 主体 -->
            <div class="main_box wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
                <!-- 顶部 -->
                <div class="main_box_top wow animate__slideInUp" data-wow-delay="0" data-wow-iteration="1"
                    :data-wow-offset="height_top">
                    <div class="main_box_top_l">
                        <img src="../../assets/imgs/app_developmentvalue_0.png" class="main_box_top_l_img" alt="">
                    </div>
                    <div class="main_box_top_r">
                        <div class="main_box_top_r_title wow animate__slideInUp" data-wow-delay="0.2s"
                            data-wow-iteration="1" :data-wow-offset="height_top">APP定制开发价值</div>
                        <div class="main_box_top_r_box wow animate__slideInUp" data-wow-delay="0.4s" data-wow-iteration="1"
                            :data-wow-offset="height_top">
                            <div v-if="list.length > 0" class="main_box_top_r_item wow animate__slideInUp"
                                data-wow-delay="0.2s" data-wow-iteration="1" :data-wow-offset="height_top">
                                <div class="main_box_top_r_item_img_box">
                                    <img src="../../assets/imgs/20230724174121.png" class="main_box_top_r_item_img_box_img"
                                        alt="">
                                    <span>01</span>
                                </div>
                                <div class="main_box_top_r_item_r">
                                    <div class="main_box_top_r_item_r_title">{{ list[0].title }}</div>
                                    <div class="main_box_top_r_item_r_txt">{{ list[0].summary }}</div>
                                </div>
                            </div>
                            <div v-if="list.length > 1" class="main_box_top_r_item wow animate__slideInUp"
                                data-wow-delay="0.3s" data-wow-iteration="1" :data-wow-offset="height_top">
                                <div class="main_box_top_r_item_img_box">
                                    <img src="../../assets/imgs/20230724174121.png" class="main_box_top_r_item_img_box_img"
                                        alt="">
                                    <span>02</span>
                                </div>
                                <div class="main_box_top_r_item_r">
                                    <div class="main_box_top_r_item_r">
                                        <div class="main_box_top_r_item_r_title">{{ list[1].title }}</div>
                                        <div class="main_box_top_r_item_r_txt">{{ list[1].summary }}</div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="list.length > 2" class="main_box_top_r_item wow animate__slideInUp"
                                data-wow-delay="0.4s" data-wow-iteration="1" :data-wow-offset="height_top">
                                <div class="main_box_top_r_item_img_box">
                                    <img src="../../assets/imgs/20230724174121.png" class="main_box_top_r_item_img_box_img"
                                        alt="">
                                    <span>03</span>
                                </div>
                                <div class="main_box_top_r_item_r">
                                    <div class="main_box_top_r_item_r">
                                        <div class="main_box_top_r_item_r_title">{{ list[2].title }}</div>
                                        <div class="main_box_top_r_item_r_txt">{{ list[2].summary }}</div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="list.length > 3" class="main_box_top_r_item wow animate__slideInUp"
                                data-wow-delay="0.5s" data-wow-iteration="1" :data-wow-offset="height_top">
                                <div class="main_box_top_r_item_img_box">
                                    <img src="../../assets/imgs/20230724174121.png" class="main_box_top_r_item_img_box_img"
                                        alt="">
                                    <span>04</span>
                                </div>
                                <div class="main_box_top_r_item_r">
                                    <div class="main_box_top_r_item_r">
                                        <div class="main_box_top_r_item_r_title">{{ list[3].title }}</div>
                                        <div class="main_box_top_r_item_r_txt">{{ list[3].summary }}</div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="list.length > 4" class="main_box_top_r_item wow animate__slideInUp"
                                data-wow-delay="0.6s" data-wow-iteration="1" :data-wow-offset="height_top">
                                <div class="main_box_top_r_item_img_box">
                                    <img src="../../assets/imgs/20230724174121.png" class="main_box_top_r_item_img_box_img"
                                        alt="">
                                    <span>05</span>
                                </div>
                                <div class="main_box_top_r_item_r">
                                    <div class="main_box_top_r_item_r">
                                        <div class="main_box_top_r_item_r_title">{{ list[4].title }}</div>
                                        <div class="main_box_top_r_item_r_txt">{{ list[4].summary }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 定制 -->
                <div class="main_box_center wow animate__slideInUp" data-wow-delay="0" data-wow-iteration="1"
                    :data-wow-offset="height_top">
                    <div class="title_box wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
                        行业定制解决方案
                    </div>
                    <div class="main_box_center_box">
                        <div class="main_box_center_box_l wow animate__slideInUp" data-wow-delay="0.2s"
                            data-wow-iteration="1" :data-wow-offset="height_top">
                            <div class="main_box_center_box_l_item" @mouseover="mouseover(item)"
                                v-for="(item, index) in list1" :key="index">
                                <div class="main_box_center_box_l_item_img_box">
                                    <img :src="item.image" class="main_box_center_box_l_item_img_box_img" alt="">
                                </div>
                                <div>{{ item.title }}</div>
                            </div>
                        </div>
                        <div class="main_box_center_box_r wow animate__slideInUp" data-wow-delay="0.4s"
                            data-wow-iteration="1" :data-wow-offset="height_top">
                            <div class="main_box_center_box_r_img_box">
                                <img :src="obj.annex" class="main_box_center_box_r_img_box_img" alt="">
                            </div>
                            <div class="main_box_center_box_r_btn">
                                <div class="main_box_center_box_r_btn_l">{{ obj.title }}</div>
                                <div class="main_box_center_box_r_btn_r">{{ obj.summary }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 推荐 -->
                <div class="title_box wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
                    <div>推荐案例</div>
                    <div class="title_box_text">
                        眼光高度决定品牌厚度！
                    </div>
                </div>
                <div class="main_box_center_btn wow animate__slideInUp" data-wow-iteration="1"
                    :data-wow-offset="height_top">
                    <div @click="toDetails(item.id)" v-for="(item, index) in list2" :key="index"
                        class="main_box_center_btn_box wow animate__slideInUp" :data-wow-delay="index / 10 + 0.2 + 's'"
                        data-wow-iteration="1" :data-wow-offset="height_top">
                        <div class="main_box_center_btn_item">
                            <div class="main_box_center_btn_item_img_box" ref="btnItem"
                                :style="{ height: tempHeights + 'px' }">
                                <img :src="item.list_image1" class="main_box_center_btn_item_img" alt />
                            </div>
                            <div class="main_box_center_btn_item_box">
                                <div class="main_box_center_btn_item_box_title">{{ item.title }}</div>
                                <div class="main_box_center_btn_item_box_txt">{{ item.summary }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <Footer class="wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top" />
    </div>
</template>
  
<script>
import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";
import storage from "@/utils/storage";
import { articlelist14, articlelist11, projectsrecommends } from "@/api/axios/axios.api";
import { WOW } from "wowjs";
export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            height_top: storage.height_top,
            tempHeights: '',
            timer: null,
            list: [],
            list1: [],
            list2: [],
            obj: {},
        };
    },
    methods: {
        // 案例详情
        toDetails(e) {
            this.$router.push({
                name: 'sucsessdetails',
                // query: {
                //     id: e.id
                // }
            })
        },
        // 设置高度
        height() {
            //进入页面元素默认宽度
            this.timer = setInterval(() => {
                if (!this.$refs.btnItem) {
                    this.tempHeights = this.$refs.btnItem[0].offsetWidth
                    clearInterval(this.timer)
                    this.timer = null
                } else {
                    clearInterval(this.timer)
                    this.timer = null
                }
            }, 0);
            //根据屏幕缩放自动获取页面宽度
            window.onresize = () => {
                return (() => {
                    //窗口缩放自动获取元素宽度
                    this.tempHeights = this.$refs.btnItem[0].offsetWidth //宽度
                })()
            }
        },
        // 鼠标移入
        mouseover(item) {
            this.obj = item
        },
        // 获取数据
        async getData() {
            // 开发价值
            let res = await articlelist14()
            this.list = res.data.list
            // 解决方案
            let rec = await articlelist11()
            this.list1 = rec.data.list
            this.obj = rec.data.list[0]
            // 案例推荐
            let ret = await projectsrecommends(4)
            this.list2 = ret.data.list
            if (ret.data.list.length) {
                this.height();
            }
        },
    },
    created() { },
    mounted() {
        this.getData()
        this.$nextTick(() => {
            let wow = new WOW({
                live: true,
            });
            wow.init();
        });
    },
    watch: {},
    beforeDestroy() {
        console.log("销毁定时器");
        clearInterval(this.timer);
    },
};
</script>
  
<style lang="less" scoped>
@media screen and (min-width : 1400px) {
    .banner {
        color: #fff;
        height: 1012px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        justify-content: space-between;
        overflow: hidden;

        .banner_img {
            position: absolute;
            height: 1012px;
            width: 100%;
        }

        .banner_box {
            position: relative;
            z-index: 100;
            color: #FFFFFF;
            text-align: center;
            margin-top: 70px;

            .banner_box_title {
                font-size: 50px;
                margin-bottom: 47px;
            }

            .banner_box_center {
                font-size: 30px;
                margin-bottom: 52px;
            }

            .banner_box_txt {
                font-size: 18px;
                margin-bottom: 70px;
            }
        }

        .banner_btn_box {
            position: relative;
            z-index: 100;
            display: flex;
            justify-content: center;

            .banner_btn_box {
                width: 422px;
                height: 633px;
                margin: 0 50px;

                .banner_btn_box_img {
                    width: 422px;
                    height: 633px;
                    transition: all 0.5s;
                }

                .banner_btn_box_img:hover {
                    // transform: scale(1.2);
                    transition: all 0.5s;
                    // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
                }
            }

            .banner_btn_box_1 {
                width: 204px;
                height: 232px;
                margin-top: 100px;

                .banner_btn_box_1_img {
                    width: 204px;
                    height: 232px;
                    transition: all 0.5s;
                }

                .banner_btn_box_1_img:hover {
                    // transform: scale(1.2);
                    transition: all 0.5s;
                    // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
                }
            }
        }
    }

    // 身体
    .main_box {
        background-color: #fff;

        // 头部
        .main_box_top {
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            height: 770px;

            .main_box_top_l {
                width: 696px;
                // width: 30%;
                height: 770px;
                background: #396DC6;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                overflow: hidden;

                .main_box_top_l_img {
                    width: 326px;
                    background: #396DC6;
                    transition: all 0.5s;
                }

                .main_box_top_l_img:hover {
                    transition: all 0.5s;
                    // transform: scale(1.2);
                }
            }

            .main_box_top_r {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .main_box_top_r_title {
                    color: #333333;
                    font-size: 50px;
                    padding: 60px 0;
                    text-align: center;
                }

                .main_box_top_r_box {
                    width: 100%;
                    height: calc(100% - 66px);
                    margin-left: -10%;
                    position: relative;
                    z-index: 100;
                    background-color: #fff;
                    transition: all 0.5s;
                    box-sizing: border-box;

                    .main_box_top_r_item {
                        width: 100%;
                        height: calc(100% / 5);
                        display: flex;
                        align-items: center;
                        position: relative;
                        overflow: hidden;
                        border: 1px solid #E5E5E5;
                        box-sizing: border-box;

                        .main_box_top_r_item_img_box {
                            width: 72px;
                            height: 77px;
                            position: relative;
                            margin: 0 37px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 30px;
                            color: #FFFFFF;
                            transition: all 0.5s;
                            // overflow: hidden;

                            .main_box_top_r_item_img_box_img {
                                width: 72px;
                                // height: 77px;
                                // position: absolute;
                                // top: 0;
                                // left: 0;
                                transition: all 0.5s;
                            }

                            span {
                                position: absolute;
                                top: 17px;
                                left: 20px;
                                z-index: 100;
                                transition: all 0.5s;
                            }
                        }

                        .main_box_top_r_item_r {
                            .main_box_top_r_item_r_title {
                                font-size: 18px;
                                color: #333333;
                                margin-bottom: 20px;
                            }

                            .main_box_top_r_item_r_txt {
                                font-size: 16px;
                                color: #999999;
                            }
                        }
                    }

                    .main_box_top_r_item:nth-child(2),
                    .main_box_top_r_item:nth-child(3),
                    .main_box_top_r_item:nth-child(4),
                    .main_box_top_r_item:nth-child(5) {
                        border-top: 0;
                    }

                    .main_box_top_r_item:nth-child(5) {
                        border-bottom: 0;
                    }

                    .main_box_top_r_item:hover {
                        transition: all 0.5s;
                        box-shadow: 0 0 20px rgba(153, 153, 153, 1);
                    }

                    .main_box_top_r_item:hover .main_box_top_r_item_img_box span {
                        transform: scale(1.2);
                        transition: all 0.5s;
                        // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
                    }

                    .main_box_top_r_item:hover .main_box_top_r_item_img_box_img {
                        transform: scale(1.2);
                        transition: all 0.5s;
                        // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
                    }
                }
            }

            .main_box_top_r_item:hover .main_box_top_r_img {
                transform: scale(1.1);
                transition: all 0.5s;
                // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
            }
        }

        // 定制
        .main_box_center {
            padding: 0 10% 8% 10%;
            height: 936px;
            // background: url("../../assets/imgs/shouye_04.png") no-repeat;
            // background-size: 100% 100%;
            background: #EDF1FC;
            box-sizing: border-box;

            .title_box {
                text-align: center;
                font-size: 50px;
                color: #333333;
                padding: 70px 0;
                background: transparent;
            }

            .main_box_center_box {
                display: flex;
                justify-content: space-between;

                .main_box_center_box_l {
                    width: 600px;
                    color: #FFFFFF;
                    font-size: 18px;
                    display: flex;
                    flex-wrap: wrap;
                    margin-right: 16px;

                    .main_box_center_box_l_item {
                        width: 200px;
                        height: 167px;
                        background: #396DC6;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        // transition: all 0.5s;
                        justify-content: center;

                        .main_box_center_box_l_item_img_box {
                            width: 45px;
                            height: 42px;
                            margin-bottom: 26px;

                            .main_box_center_box_l_item_img_box_img {
                                width: 45px;
                                height: 42px;
                                transition: all 0.5s;
                            }
                        }
                    }

                    .main_box_center_box_l_item:nth-child(odd) {
                        background: #4E80D7;
                    }

                    .main_box_center_box_l_item:hover {
                        // transition: all 0.5s;
                        border: 6px solid #FFFFFF;
                        // color: #000;
                    }

                    .main_box_center_box_l_item:hover .main_box_center_box_l_item_img_box_img {
                        transform: scale(1.2);
                        transition: all 0.5s;
                    }
                }

                .main_box_center_box_r {
                    flex: 1;
                    background-color: #fff;

                    .main_box_center_box_r_img_box {
                        width: 498px;
                        height: 309px;
                        margin: 96px auto;
                        transition: all 0.5s;

                        .main_box_center_box_r_img_box_img {
                            width: 498px;
                            height: 309px;
                        }
                    }

                    .main_box_center_box_r_btn {
                        display: flex;
                        align-items: center;
                        height: 70px;

                        .main_box_center_box_r_btn_l {
                            padding: 0 40px 0 80px;
                            height: 70px;
                            line-height: 70px;
                            border-right: 1px solid #E5E5E5;
                            font-size: 24px;
                            color: #3D6DC6;
                        }

                        .main_box_center_box_r_btn_r {
                            flex: 1;
                            height: 70px;
                            // line-height: 70px;
                            font-size: 16px;
                            color: #333333;
                            padding-left: 40px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            // overflow: hidden;
                            // text-overflow: ellipsis;
                            // display: -webkit-box;
                            // -webkit-box-orient: vertical;
                            // -webkit-line-clamp: 1;
                        }
                    }

                    .main_box_center_box_r_img_box:hover {
                        transform: scale(1.1);
                        transition: all 0.5s;
                        // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
                    }
                }
            }
        }

        // 推荐
        .title_box {
            padding: 50px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #333333;
            font-size: 28px;
            background-color: #f5f5f5;

            .title_box_text {
                margin-top: 21px;
                font-weight: normal;
                color: #666666;
                font-size: 18px;
            }
        }

        .main_box_center_btn {
            padding: 0 10%;
            padding-bottom: 66px;
            display: flex;
            justify-content: space-between;
            background-color: #f5f5f5;
            align-items: center;

            .main_box_center_btn_box {
                width: calc(100% / 3);
                display: flex;
                align-items: center;
                justify-content: center;

                .main_box_center_btn_item {
                    // width: 407px;
                    width: 100%;
                    // height: 514px;
                    background: #FFFFFF;
                    padding: 2%;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    cursor: pointer;
                    transition: all 0.5s;

                    .main_box_center_btn_item_img_box {
                        width: 100%;
                        // height: 378px;
                        overflow: hidden;

                        .main_box_center_btn_item_img {
                            width: 100%;
                            height: 100%;
                            transition: all 0.5s;
                        }

                    }

                    .main_box_center_btn_item_box {
                        flex: 1;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding: 8% 0;

                        .main_box_center_btn_item_box_title {
                            color: #333333;
                            font-size: 20px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            margin-bottom: 5%;
                        }

                        .main_box_center_btn_item_box_txt {
                            color: #999999;
                            font-size: 14px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                        }
                    }

                }

                .main_box_center_btn_item:hover {
                    transition: all 0.5s;
                    box-shadow: 0 0 20px rgba(153, 153, 153, 1);
                }

                .main_box_center_btn_item:hover .main_box_center_btn_item_img {
                    transform: scale(1.5);
                    transition: all 0.5s;
                }
            }

            .main_box_center_btn_box:nth-child(2) {
                margin: 0 5%;
            }
        }
    }
}

@media screen and (max-width : 1399px) {
    .banner {
        min-width: 1300px;
        color: #fff;
        height: 1012px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        justify-content: space-between;
        overflow: hidden;

        .banner_img {
            position: absolute;
            height: 1012px;
            width: 100%;
        }

        .banner_box {
            position: relative;
            z-index: 100;
            color: #FFFFFF;
            text-align: center;
            margin-top: 70px;

            .banner_box_title {
                font-size: 50px;
                margin-bottom: 47px;
            }

            .banner_box_center {
                font-size: 30px;
                margin-bottom: 52px;
            }

            .banner_box_txt {
                font-size: 18px;
                margin-bottom: 70px;
            }
        }

        .banner_btn_box {
            position: relative;
            z-index: 100;
            display: flex;
            justify-content: center;

            .banner_btn_box {
                width: 422px;
                height: 633px;
                margin: 0 50px;

                .banner_btn_box_img {
                    width: 422px;
                    height: 633px;
                    transition: all 0.5s;
                }

                .banner_btn_box_img:hover {
                    // transform: scale(1.2);
                    transition: all 0.5s;
                    // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
                }
            }

            .banner_btn_box_1 {
                width: 204px;
                height: 232px;
                margin-top: 100px;

                .banner_btn_box_1_img {
                    width: 204px;
                    height: 232px;
                    transition: all 0.5s;
                }

                .banner_btn_box_1_img:hover {
                    // transform: scale(1.2);
                    transition: all 0.5s;
                    // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
                }
            }
        }
    }

    // 身体
    .main_box {
        background-color: #fff;
        min-width: 1300px;

        // 头部
        .main_box_top {
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            height: 770px;

            .main_box_top_l {
                // width: 696px;
                height: 770px;
                width: 30%;
                height: 100%;
                background: #396DC6;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                overflow: hidden;

                .main_box_top_l_img {
                    width: 326px;
                    background: #396DC6;
                    transition: all 0.5s;
                }

                .main_box_top_l_img:hover {
                    transition: all 0.5s;
                    // transform: scale(1.2);
                }
            }

            .main_box_top_r {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .main_box_top_r_title {
                    color: #333333;
                    font-size: 50px;
                    padding: 60px 0;
                    text-align: center;
                }

                .main_box_top_r_box {
                    width: 100%;
                    height: calc(100% - 66px);
                    margin-left: 0;
                    position: relative;
                    z-index: 100;
                    background-color: #fff;
                    transition: all 0.5s;
                    box-sizing: border-box;

                    .main_box_top_r_item {
                        width: 100%;
                        height: calc(100% / 5);
                        display: flex;
                        align-items: center;
                        position: relative;
                        overflow: hidden;
                        border: 1px solid #E5E5E5;
                        box-sizing: border-box;

                        .main_box_top_r_item_img_box {
                            width: 72px;
                            height: 77px;
                            position: relative;
                            margin: 0 37px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 30px;
                            color: #FFFFFF;
                            transition: all 0.5s;
                            // overflow: hidden;

                            .main_box_top_r_item_img_box_img {
                                width: 72px;
                                // height: 77px;
                                // position: absolute;
                                // top: 0;
                                // left: 0;
                                transition: all 0.5s;
                            }

                            span {
                                position: absolute;
                                top: 17px;
                                left: 20px;
                                z-index: 100;
                                transition: all 0.5s;
                            }
                        }

                        .main_box_top_r_item_r {
                            .main_box_top_r_item_r_title {
                                font-size: 18px;
                                color: #333333;
                                margin-bottom: 20px;
                            }

                            .main_box_top_r_item_r_txt {
                                font-size: 16px;
                                color: #999999;
                            }
                        }
                    }

                    .main_box_top_r_item:nth-child(2),
                    .main_box_top_r_item:nth-child(3),
                    .main_box_top_r_item:nth-child(4),
                    .main_box_top_r_item:nth-child(5) {
                        border-top: 0;
                    }

                    .main_box_top_r_item:nth-child(5) {
                        border-bottom: 0;
                    }

                    .main_box_top_r_item:hover {
                        transition: all 0.5s;
                        box-shadow: 0 0 20px rgba(153, 153, 153, 1);
                    }

                    .main_box_top_r_item:hover .main_box_top_r_item_img_box span {
                        transform: scale(1.2);
                        transition: all 0.5s;
                        // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
                    }

                    .main_box_top_r_item:hover .main_box_top_r_item_img_box_img {
                        transform: scale(1.2);
                        transition: all 0.5s;
                        // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
                    }
                }
            }

            .main_box_top_r_item:hover .main_box_top_r_img {
                transform: scale(1.1);
                transition: all 0.5s;
                // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
            }
        }

        // 定制
        .main_box_center {
            padding: 0 20px 8% 20px;
            height: 936px;
            // background: url("../../assets/imgs/shouye_04.png") no-repeat;
            // background-size: 100% 100%;
            background: #EDF1FC;
            box-sizing: border-box;

            .title_box {
                text-align: center;
                font-size: 50px;
                color: #333333;
                padding: 70px 0;
                background: transparent;
            }

            .main_box_center_box {
                display: flex;
                justify-content: space-between;

                .main_box_center_box_l {
                    width: 600px;
                    color: #FFFFFF;
                    font-size: 18px;
                    display: flex;
                    flex-wrap: wrap;
                    margin-right: 16px;

                    .main_box_center_box_l_item {
                        width: 200px;
                        height: 167px;
                        background: #396DC6;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        // transition: all 0.5s;
                        justify-content: center;

                        .main_box_center_box_l_item_img_box {
                            width: 45px;
                            height: 42px;
                            margin-bottom: 26px;

                            .main_box_center_box_l_item_img_box_img {
                                width: 45px;
                                height: 42px;
                                transition: all 0.5s;
                            }
                        }
                    }

                    .main_box_center_box_l_item:nth-child(odd) {
                        background: #4E80D7;
                    }

                    .main_box_center_box_l_item:hover {
                        // transition: all 0.5s;
                        border: 6px solid #FFFFFF;
                        // color: #000;
                    }

                    .main_box_center_box_l_item:hover .main_box_center_box_l_item_img_box_img {
                        transform: scale(1.2);
                        transition: all 0.5s;
                    }
                }

                .main_box_center_box_r {
                    flex: 1;
                    background-color: #fff;

                    .main_box_center_box_r_img_box {
                        width: 498px;
                        height: 309px;
                        margin: 96px auto;
                        transition: all 0.5s;

                        .main_box_center_box_r_img_box_img {
                            width: 498px;
                            height: 309px;
                        }
                    }

                    .main_box_center_box_r_btn {
                        display: flex;
                        align-items: center;
                        height: 70px;

                        .main_box_center_box_r_btn_l {
                            padding: 0 40px 0 80px;
                            height: 70px;
                            line-height: 70px;
                            border-right: 1px solid #E5E5E5;
                            font-size: 24px;
                            color: #3D6DC6;
                        }

                        .main_box_center_box_r_btn_r {
                            flex: 1;
                            height: 70px;
                            // line-height: 70px;
                            font-size: 16px;
                            color: #333333;
                            padding-left: 40px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            // overflow: hidden;
                            // text-overflow: ellipsis;
                            // display: -webkit-box;
                            // -webkit-box-orient: vertical;
                            // -webkit-line-clamp: 1;
                        }
                    }

                    .main_box_center_box_r_img_box:hover {
                        transform: scale(1.1);
                        transition: all 0.5s;
                        // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
                    }
                }
            }
        }

        // 推荐
        .title_box {
            padding: 50px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #333333;
            font-size: 28px;
            background-color: #f5f5f5;

            .title_box_text {
                margin-top: 21px;
                font-weight: normal;
                color: #666666;
                font-size: 18px;
            }
        }

        .main_box_center_btn {
            padding: 0 20px;
            padding-bottom: 66px;
            display: flex;
            justify-content: space-between;
            background-color: #f5f5f5;
            align-items: center;

            .main_box_center_btn_box {
                width: calc(100% / 3);
                display: flex;
                align-items: center;
                justify-content: center;

                .main_box_center_btn_item {
                    // width: 407px;
                    width: 100%;
                    // height: 514px;
                    background: #FFFFFF;
                    padding: 2%;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    cursor: pointer;
                    transition: all 0.5s;

                    .main_box_center_btn_item_img_box {
                        width: 100%;
                        // height: 378px;
                        overflow: hidden;

                        .main_box_center_btn_item_img {
                            width: 100%;
                            height: 100%;
                            transition: all 0.5s;
                        }

                    }

                    .main_box_center_btn_item_box {
                        flex: 1;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding: 8% 0;

                        .main_box_center_btn_item_box_title {
                            color: #333333;
                            font-size: 20px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            margin-bottom: 5%;
                        }

                        .main_box_center_btn_item_box_txt {
                            color: #999999;
                            font-size: 14px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                        }
                    }

                }

                .main_box_center_btn_item:hover {
                    transition: all 0.5s;
                    box-shadow: 0 0 20px rgba(153, 153, 153, 1);
                }

                .main_box_center_btn_item:hover .main_box_center_btn_item_img {
                    transform: scale(1.5);
                    transition: all 0.5s;
                }
            }

            .main_box_center_btn_box:nth-child(2) {
                margin: 0 1%;
            }
        }
    }
}
</style>
  